export default function useCheckGeoAvailability() {
  const stFetch = useRawStFetch()

  async function fetchGeoAvailability() {
    try {
      await stFetch('/geo/availability/assert', {})
    } catch (error) {
      console.error(error)
    }
  }

  return { fetchGeoAvailability }
}
